@use '../helpers.scss' as *;

$spkSelectNew: false !default;

@if $spkSelectNew == true {
  spk-select-new {
    --miw: #{p2r(210)};

    display: flex;

    spk-form-field {
      display: flex;
      min-width: var(--miw);
    }

    spk-popover {
      flex: 1 0;

      &:has(.sparkle-options:empty) .popover {
        opacity: 0;
      }
    }

    .input {
      display: flex;
      flex-wrap: wrap;

      input {
        opacity: 0;
        width: 0;
        height: 0;
      }
    }

    .selected-value {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: p2r(4);
      color: var(--base-level-60);

      &.is-selected {
        color: var(--base-level-70);
      }
    }

    .input {
      position: relative;

      &.show-search-text {
        input {
          opacity: 1;
          height: initial;
          margin-top: p2r(4);
          min-width: min-content;
          field-sizing: content;
        }
      }
    }
  }

  .sparkle-options {
    padding: p2r(8 0);
    display: flex;
    flex-direction: column;
    max-height: p2r(180);
    overflow-y: auto;
    overflow-x: hidden;

    .option {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      min-height: p2r(32);
      margin: p2r(0 8);
      padding: p2r(0 8);
      gap: p2r(8);
      width: calc(100% - #{p2r(16)});
      border-radius: var(--shape-2);
      appearance: none;
      background-color: transparent;
      border: 0;
      cursor: pointer;
      color: var(--base-level-70);
      user-select: none;

      &[disabled] {
        opacity: 0.3;
        cursor: initial;

        &:hover {
          background-color: transparent;
        }
      }

      spk-icon {
        font-size: p2r(16);
      }

      &.active,
      &:checked,
      &:hover {
        background-color: var(--base-level-30);
        color: var(--base-level-70);
      }

      &.focused {
        background-color: var(--primary-level-20);
        color: var(--base-level-70);

        &:hover {
          background-color: var(--primary-level-30);
        }
      }
    }
  }
}
