// @use '@sparkle-ui/core/styles';
@use '@sparkle-ui/core/styles' as *;
@use '@sparkle-ui/core/styles/helpers' as *;

[table-no-rows] {
  display: flex;
  flex-direction: column;
  gap: p2r(16);
  align-items: center;
  justify-content: center;
  min-height: p2r(320);
}

:root dialog[spkDialog] {
  --dialog-p: #{p2r(32)};
}
