@use '../helpers.scss' as *;

$spkTooltip: true !default;

@if $spkTooltip == true {
  spk-tooltip {
    --tt-bg: var(--base-level-30);
    --tt-c: var(--base-level-30R);
    --tt-d: inline-flex;

    display: var(--tt-d);

    .trigger {
      display: inherit;
    }

    &.primary {
      --tt-bg: var(--primary-level-50);
      --tt-c: var(--primary-level-50R);
    }

    &.accent {
      --tt-bg: var(--accent-level-50);
      --tt-c: var(--accent-level-50R);
    }

    &.warn {
      --tt-bg: var(--error-level-50);
      --tt-c: var(--error-level-50R);
    }

    &.error {
      --tt-bg: var(--error-level-50);
      --tt-c: var(--error-level-50R);
    }

    &.success {
      --tt-bg: var(--success-level-50);
      --tt-c: var(--success-level-50R);
    }

    &.disabled .tooltip,
    &.disabled .tooltip::before {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      user-select: none;
    }

    .tooltip {
      position: relative;
      margin: 0;
      z-index: 0;
      transform: translateX(-50%) scale(0.2);
      color: transparent;
      padding: p2r(8 12);
      background-color: var(--tt-bg);
      max-width: #{p2r(240)};
      text-wrap: balance;
      width: max-content;
      height: max-content;
      font: var(--paragraph-30);
      border-radius: var(--shape-2);
      border: 0;
      opacity: 0;
      overflow: visible;
      transition:
        opacity 125ms linear,
        transform 125ms linear,
        color 75ms linear 50ms;

      &:popover-open {
        opacity: 1;
        transform: translateX(-50%) scale(1);
        color: var(--tt-c);
      }

      &:before {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-width: p2r(6);
        border-style: solid;
        border-color: transparent;
        border-top-color: var(--tt-bg);
        width: 0;
        height: 0;
        opacity: 1;
      }

      @supports (left: anchor(center)) and (top: anchor(bottom)) {
        left: anchor(center);
        bottom: anchor(top);
        margin-bottom: p2r(10);
        top: auto;
      }

      // \@starting-style .tooltip {
      //   display: block;
      //   transition-behavior: allow-discrete;
      // }
    }
  }

  [spk-tooltip],
  [tt-primary],
  [tt-accent],
  [tt-warn],
  [tt-error],
  [tt-success] {
    --tt-bg: var(--base-level-30);
    --tt-c: var(--base-level-30R);

    position: relative;

    &:after {
      content: attr(spk-tooltip);
      position: absolute;
      bottom: calc(100% + #{p2r(12)});
      left: 50%;
      transform: translateX(-50%);
      padding: p2r(8 12);
      background-color: var(--tt-bg);
      color: var(--tt-c);
      max-width: #{p2r(200)};
      text-wrap: balance;
      width: max-content;
      height: max-content;
      font: var(--paragraph-30);
      border-radius: var(--shape-2);
      opacity: 0;
      visibility: hidden;
      transition:
        opacity 125ms linear,
        visibility 125ms linear;
      pointer-events: none;
      user-select: none;
      z-index: 1000;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: calc(100% - #{p2r(4)});
      left: 50%;
      transform: translateX(-50%);
      border-width: p2r(8);
      border-style: solid;
      border-color: transparent;
      border-top-color: var(--tt-bg);
      width: 0;
      height: 0;
      pointer-events: none;
      opacity: 0;
      visibility: hidden;
      transition:
        opacity 125ms linear,
        visibility 125ms linear;
      z-index: 1000;
    }

    &:hover:after,
    &:hover:before {
      opacity: 1;
      visibility: visible;
    }
  }

  [tt-primary] {
    --tt-bg: var(--primary-level-50);
    --tt-c: var(--primary-level-50R);

    &:after {
      content: attr(tt-primary);
    }
  }

  [tt-accent] {
    --tt-bg: var(--accent-level-50);
    --tt-c: var(--accent-level-50R);

    &:after {
      content: attr(tt-accent);
    }
  }

  [tt-warn] {
    --tt-bg: var(--error-level-50);
    --tt-c: var(--error-level-50R);

    &:after {
      content: attr(tt-warn);
    }
  }

  [tt-error] {
    --tt-bg: var(--error-level-50);
    --tt-c: var(--error-level-50R);

    &:after {
      content: attr(tt-error);
    }
  }

  [tt-success] {
    --tt-bg: var(--success-level-50);
    --tt-c: var(--success-level-50R);

    &:after {
      content: attr(tt-success);
    }
  }
}

[spk-tooltip][tt-left],
[tt-primary][tt-left],
[tt-accent][tt-left],
[tt-warn][tt-left],
[tt-error][tt-left],
[tt-success][tt-left] {
  &:after {
    top: 50%;
    left: auto;
    right: calc(100% + #{p2r(12)});
    transform: translateY(-50%);
  }

  &:before {
    top: 50%;
    left: auto;
    right: calc(100% - #{p2r(4)});
    transform: translateY(-50%);
    border-top-color: transparent;
    border-left-color: var(--tt-bg);
  }
}

[spk-tooltip][tt-right],
[tt-primary][tt-right],
[tt-accent][tt-right],
[tt-warn][tt-right],
[tt-error][tt-right],
[tt-success][tt-right] {
  &:after {
    top: 50%;
    left: calc(100% + #{p2r(12)});
    transform: translateY(-50%);
  }

  &:before {
    top: 50%;
    left: calc(100% - #{p2r(4)});
    transform: translateY(-50%);
    border-top-color: transparent;
    border-right-color: var(--tt-bg);
  }
}

[spk-tooltip][tt-bottom],
[tt-primary][tt-bottom],
[tt-accent][tt-bottom],
[tt-warn][tt-bottom],
[tt-error][tt-bottom],
[tt-success][tt-bottom] {
  &:after {
    top: calc(100% + #{p2r(12)});
  }

  &:before {
    top: calc(100% - #{p2r(4)});
    transform: translateX(-50%);
    border-top-color: transparent;
    border-bottom-color: var(--tt-bg);
  }
}
