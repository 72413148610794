@use '../helpers.scss' as *;

$spkFormField: true !default;

@if $spkFormField == true {
  spk-form-field-popover,
  spk-form-field {
    --ff-h: #{p2r(40)};
    --ff-s: var(--shape-2);
    --ff-bc: var(--base-level-40);
    --ff-prefix-bg: var(--base-level-20);
    --ff-ic: var(--base-level-60);
    --ff-bg: var(--base-level-10);
    --ff-spinner-size: #{p2r(20)};
    --ff-spinner-thickness: #{p2r(2)};

    display: flex;
    flex-direction: column;
    position: relative;

    &[read-only],
    &[read-only='true'],
    &[readonly='true'],
    &[readonly],
    &.readonly,
    &[disabled],
    &[disabled='true'],
    &.disabled,
    &:has(input[disabled]),
    &:has(textarea[disabled]),
    &:has(textarea[disabled='true']),
    &:has(input[disabled='true']) {
      pointer-events: none;
      user-select: none;
      opacity: 0.5;
    }

    &.small {
      --ff-h: #{p2r(32)};
    }

    label {
      display: flex;
      align-items: center;
      padding-bottom: p2r(4);
      gap: p2r(4);

      spk-icon {
        font-size: p2r(16);
      }
    }

    .prefix {
      display: none;
      align-items: center;
      gap: p2r(4);
      background-color: var(--ff-prefix-bg);
      padding: p2r(0 12);
      height: 100%;
      min-height: calc(var(--ff-h) - #{p2r(2)});
      transition: background-color 125ms linear;

      [prefix] + [textPrefix] {
        margin-left: p2r(4);
      }
    }

    .prefix-space,
    .suffix-space {
      width: 0;
      height: 100%;
    }

    &:not(:has(.helpers .error:empty)) {
      --ff-ic: var(--error-500);
      --ff-bc: var(--error-500);
      --ff-prefix-bg: var(--error-level-20);
    }

    &.autosize .input-wrap {
      height: auto;
      min-height: var(--ff-h);
    }

    .input-wrap {
      display: flex;
      align-items: center;
      border: 1px solid var(--ff-bc);
      border-radius: var(--ff-s);
      background: var(--ff-bg);
      padding: p2r(7 12);
      min-height: var(--ff-h);
      overflow: hidden;
      transition: border-color 125ms linear;

      spk-popover,
      .input {
        flex: 1 0;
      }

      textarea {
        resize: none;
        margin: p2r(8 0);
        color: var(--base-level-60);
        field-sizing: content;
      }

      &:has(textarea) {
        height: auto;
        min-height: var(--ff-h);
      }

      spk-icon {
        color: var(--ff-ic);
        transition: color 125ms linear;
      }

      spk-chip spk-icon {
        color: var(--chip-ic);
      }

      spk-spinner {
        --spinner-size: var(--ff-spinner-size);
        --spinner-thickness: var(--ff-spinner-thickness);
      }

      textarea,
      input {
        appearance: none;
        border: 0;
        padding: 0;
        background: transparent;
        font: var(--paragraph-30B);
        flex: 1 0;
        color: var(--base-level-60);

        &::placeholder {
          color: var(--base-level-60R);
        }

        &:focus {
          outline: none;
        }

        &[type='number'] {
          appearance: textfield;
          text-align: right;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            display: none;
          }
        }
      }

      &:has([suffix]) {
        .suffix-space {
          width: p2r(8);
        }
      }

      &:has([prefix]),
      &:has([textPrefix]) {
        padding: p2r(0 12 0 0);

        .prefix {
          display: flex;
        }

        .prefix-space {
          width: p2r(8);
        }
      }
    }

    .helpers {
      height: p2r(20);
      display: flex;
      align-items: center;
      gap: p2r(4);
      padding-top: p2r(4);

      &:has(.error:empty):has(.hint:empty) {
        height: 0;
        padding-top: 0;
      }

      &:not(:has(.error:empty)) {
        .hint {
          opacity: 0;
          visibility: hidden;
        }
      }

      [hint] {
        color: var(--base-level-60R);

        &[suffix] {
          justify-self: flex-end;
        }
      }
    }

    .hint {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      color: var(--base-level-60R);
      opacity: 1;
      transition:
        visibility 125ms linear,
        opacity 125ms linear;
      visibility: visible;

      &:empty {
        opacity: 0;
        // visibility: hidden;
      }
    }

    .error {
      color: var(--error-500);
      opacity: 1;
      transition: opacity 125ms linear;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;

      &:empty {
        opacity: 0;
        // visibility: hidden;
      }
    }

    &.error {
      --ff-ic: var(--error-500);
    }

    &.error {
      --ff-ic: var(--warn-500);
    }

    &.success {
      --ff-ic: var(--success-500);
    }
  }
}
