@use '../helpers.scss' as *;

$spkMenu: true !default;

@if $spkMenu == true {
  spk-menu {
    --menu-mh: #{p2r(320)};

    display: inline-flex;
    position: relative;

    spk-popover {
      flex: 1 0;

      .overlay ~ spk-form-field {
        position: sticky;
        z-index: 10;

        margin: p2r(0 4);
        top: p2r(4);
        background-color: var(--base-level-10);

        &.hidden {
          opacity: 0;
          height: 0;
          width: 0;
          min-width: 0;
          min-height: 0;
        }
      }
    }

    spk-popover .options {
      z-index: 2;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: p2r(4);
      padding: p2r(4) 0;
      max-height: var(--menu-mh);

      &.searching {
        button[disabled] {
          display: none;
        }
      }

      &:after {
        content: '';
        display: block;
        width: 100%;
        min-height: p2r(4);
      }

      [title] {
        font: var(--paragraph-30);
        color: var(--base-level-60);
        padding: p2r(8 12);
        margin: 0;
        width: calc(100% - #{p2r(16)});
        user-select: none;
      }

      button {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-height: p2r(32);
        margin: p2r(0 8);
        padding: p2r(0 8);
        gap: p2r(8);
        width: calc(100% - #{p2r(16)});
        border-radius: var(--shape-2);
        appearance: none;
        background-color: transparent;
        border: 0;
        cursor: pointer;
        color: var(--base-level-70);

        &.hide-option {
          display: none;
        }

        &[disabled] {
          opacity: 0.3;
          cursor: initial;

          &:hover {
            background-color: transparent;
          }
        }

        [suffix] {
          order: 999;
          color: var(--base-level-60);
          font: var(--paragraph-30);
          justify-self: flex-end;
          display: inline-flex;
          flex: 1 0;
          justify-content: flex-end;
        }

        spk-icon {
          font-size: p2r(16);
        }

        &.active,
        &:hover {
          background-color: var(--base-level-30);
          color: var(--base-level-70);
        }

        &.focused {
          background-color: var(--primary-level-20);
          color: var(--base-level-70);

          &:hover {
            background-color: var(--primary-level-30);
          }
        }
      }
    }
  }
}
