@use '../helpers.scss' as *;
$spkButton: true !default;

@if $spkButton == true {
  [spk-button] {
    --btn-h: #{p2r(40)};
    --btn-s: var(--shape-2);
    --btn-f: var(--paragraph-20);
    --loader-c: var(--base-level-60);
    --btn-c: var(--base-level-70);
    --btn-ic: var(--base-level-70);
    --btn-ir: var(0deg);
    --btn-bg: var(--base-level-10);
    --btn-bg-h: var(--base-level-20);
    --btn-bc: var(--base-level-40);

    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: p2r(8);
    position: relative;
    padding: p2r(0 12);
    transition: color 80ms linear;
    min-width: auto;
    cursor: pointer;
    outline: none;
    line-height: inherit;
    user-select: none;
    appearance: none;
    overflow: visible;
    vertical-align: middle;

    border-radius: var(--btn-s);
    height: var(--btn-h);
    border: 1px solid var(--btn-bc);
    font: var(--btn-f);

    background: var(--btn-bg);
    color: var(--btn-c);

    spk-icon {
      color: var(--btn-ic);
      rotate: var(--btn-ir);
      transition: rotate 125ms ease-in-out;
    }

    &:has(spk-icon:only-child) {
      padding: p2r(0 9);
    }

    &:hover {
      --btn-c: var(--base-level-70);
      background: var(--btn-bg-h);
    }

    &.loader.outlined,
    &.loader.flat,
    &.loader.raised,
    &.loader {
      color: transparent;
      width: auto;

      &:hover,
      &.primary,
      &.accent,
      &.warn,
      &.error,
      &.success {
        color: transparent;
      }

      > * {
        opacity: 0;
      }
    }

    &.rotated-icon spk-icon {
      --btn-ir: 180deg;
    }

    &.small {
      --btn-h: #{p2r(32)};
      --btn-f: var(--paragraph-30);

      padding: p2r(0 8);
      gap: p2r(4);

      &:has(spk-icon:only-child) {
        padding: p2r(0 6);
      }
    }

    &[disabled] {
      opacity: 0.5;
    }

    &[read-only],
    &[read-only='true'],
    &[readonly='true'],
    &[readonly],
    &.readonly {
      pointer-events: none;
      user-select: none;
    }

    // Base
    &.primary {
      --btn-ic: var(--primary-500);
    }

    &.accent {
      --btn-ic: var(--accent-500);
    }

    &.warn {
      --btn-ic: var(--warn-500);
    }

    &.error {
      --btn-ic: var(--error-500);
    }

    &.success {
      --btn-ic: var(--success-500);
    }

    &.icon {
      padding: p2r(0 9);

      &.small {
        padding: p2r(0 5);
      }
    }

    &.icon,
    &.outlined {
      --btn-c: var(--base-level-70);
      --btn-bg: var(--base-level-20);
      --btn-bg-h: var(--base-level-30);

      transition: color 80ms linear;

      &.primary {
        --loader-c: var(--primary-500);

        --btn-bg: var(--primary-level-20);
        --btn-bg-h: var(--primary-level-30);
        --btn-bc: var(--primary-level-40);
      }

      &.accent {
        --loader-c: var(--accent-500);

        --btn-bg: var(--accent-level-20);
        --btn-bg-h: var(--accent-level-30);
        --btn-bc: var(--accent-level-40);
      }

      &.warn {
        --loader-c: var(--warn-500);

        --btn-bg: var(--warn-level-20);
        --btn-bg-h: var(--warn-level-30);
        --btn-bc: var(--warn-level-40);
      }

      &.error {
        --loader-c: var(--error-500);

        --btn-bg: var(--error-level-20);
        --btn-bg-h: var(--error-level-30);
        --btn-bc: var(--error-level-40);
      }

      &.success {
        --loader-c: var(--success-500);

        --btn-bg: var(--success-level-20);
        --btn-bg-h: var(--success-level-30);
        --btn-bc: var(--success-level-40);
      }
    }

    &.flat {
      --loader-c: var(--base-level-10);
      --btn-c: #fff;
      --btn-ic: #fff;
      --btn-bg: #{light-dark(var(--grey-600), var(--grey-600))};
      --btn-bg-h: #{light-dark(var(--grey-700), var(--grey-700))};

      border: 0;

      &:has(spk-icon:only-child) {
        padding: p2r(0 10);
      }

      &.small:has(spk-icon:only-child) {
        padding: p2r(0 7);
      }

      &.primary {
        --btn-c: var(--primary-contrast-500);
        --btn-bg: var(--primary-500);
        --btn-bg-h: var(--primary-500);
      }

      &.accent {
        --btn-c: var(--accent-contrast-500);
        --btn-bg: var(--accent-500);
        --btn-bg-h: var(--accent-500);
      }

      &.warn {
        --btn-c: var(--warn-contrast-500);
        --btn-bg: var(--warn-500);
        --btn-bg-h: var(--warn-500);
      }

      &.error {
        --btn-c: var(--error-contrast-500);
        --btn-bg: var(--error-500);
        --btn-bg-h: var(--error-500);
      }

      &.success {
        --btn-c: var(--success-contrast-500);
        --btn-bg: var(--success-500);
        --btn-bg-h: var(--success-500);
      }
    }

    &.raised {
      --loader-c: var(--base-level-10);
      --btn-c: #fff;
      --btn-ic: #fff;
      --btn-bg: var(--base-gradient-60);
      --btn-bg-h: var(--base-gradient-70);

      border: 0;
      box-shadow: none;

      &:has(spk-icon:only-child) {
        padding: p2r(0 10);
      }

      &.small:has(spk-icon:only-child) {
        padding: p2r(0 7);
      }

      &.primary {
        --btn-c: var(--primary-contrast-500);
        --btn-bg: var(--primary-gradient-20);
        --btn-bg-h: var(--primary-gradient-30);
      }

      &.accent {
        --btn-c: var(--accent-contrast-500);
        --btn-bg: var(--accent-gradient-20);
        --btn-bg-h: var(--accent-gradient-30);
      }

      &.warn {
        --btn-c: var(--warn-contrast-500);
        --btn-bg: var(--warn-gradient-20);
        --btn-bg-h: var(--warn-gradient-30);
      }

      &.error {
        --btn-c: var(--error-contrast-500);
        --btn-bg: var(--error-gradient-20);
        --btn-bg-h: var(--error-gradient-30);
      }

      &.success {
        --btn-c: var(--success-contrast-500);
        --btn-bg: var(--success-gradient-20);
        --btn-bg-h: var(--success-gradient-30);
      }
    }
  }
}
