$useApexcharts: true !default;

@if $useApexcharts == true {
  body {
    .apexcharts-text {
      fill: var(--base-level-70);

      &.apexcharts-yaxis-label,
      &.apexcharts-xaxis-label {
        fill: var(--base-level-60);
      }
    }

    .apexcharts-gridline,
    .apexcharts-yaxis-tick,
    .apexcharts-xaxis-tick {
      stroke: var(--base-level-60R);
    }

    .apexcharts-grid-borders {
      line {
        stroke: var(--base-level-60R);
      }
    }

    .apexcharts-tracks {
      .apexcharts-radialbar-area {
        stroke: var(--base-level-40);
      }
    }

    .apexcharts-tooltip.apexcharts-theme-light {
      background-color: var(--base-level-10);
      border: 1px solid var(--base-level-40);
      box-shadow: var(--base-level-30);
      color: var(--base-level-60);

      .apexcharts-tooltip-title {
        background-color: var(--base-level-20);
        border-bottom: 1px solid var(--base-level-40);
      }
    }

    .apexcharts-xaxistooltip,
    .apexcharts-yaxistooltip {
      background-color: var(--base-level-10);
      border: 1px solid var(--base-level-40);
      box-shadow: var(--base-level-30);
      color: var(--base-level-60);
    }
  }
}
