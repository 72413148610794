@use '../helpers.scss' as *;

$spkTable: true !default;

@if $spkTable == true {
  spk-table {
    --table-bc: var(--base-level-40);
    --table-tr-bg: var(--base-level-10);
    --table-th-bg: var(--base-level-10);
    --table-th-c: var(--base-level-60R);
    --table-th-f: var(--paragraph-30);
    --table-td-c: var(--base-level-60);
    --table-td-f: var(--paragraph-30);

    --table-columns: 1fr 1fr 1fr max-content;

    width: 100%;
    display: grid;
    grid-template-columns: var(--table-columns);
    position: relative;

    thead {
      position: relative;
    }

    spk-progress-bar {
      grid-column: 1 / -1;
      position: absolute;
      top: 100%;
      transform: translateY(-50%);
    }

    tbody,
    thead,
    tr {
      display: grid;
      grid-column: 1 / -1;
      grid-template-columns: subgrid;
    }

    tr {
      background: var(--table-tr-bg);
    }

    th {
      display: flex;
      align-items: center;
      padding: p2r(0 0 0 20);
      height: p2r(48);
      font: var(--table-th-f);
      line-height: 1em;
      white-space: nowrap;
      color: var(--table-th-c);
      border: 0;
      background: var(--table-th-bg);

      &:last-child {
        padding: p2r(0 20);
      }
    }

    td {
      display: flex;
      align-items: center;

      padding: p2r(16 20);
      height: p2r(78);
      gap: p2r(8);
      white-space: nowrap;
      color: var(--table-td-c);
      font: var(--table-td-f);
      border-top: 1px solid var(--table-bc);
      border-bottom: 0;
    }

    .no-rows {
      display: none;
      grid-column: 1 / -1;
    }

    tbody:empty + .no-rows {
      display: block;
    }
  }
}
