$useInterTight: true !default;

@if ($useInterTight == true) {
  @font-face {
    font-family: 'Inter Tight';
    src: url('/sparkle-ui-assets/fonts/InterTight-VariableFont_wght.woff2') format('woff2-variations');
    font-weight: 100 900;
    font-stretch: 75% 125%;
    font-style: normal;
  }
}
