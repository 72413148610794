@use '../helpers.scss' as *;

$spkFileUpload: true !default;

@if $spkFileUpload == true {
  spk-file-upload {
    display: flex;
    flex-direction: column;
    gap: p2r(12);

    spk-list {
      border-radius: var(--shape-3);
      background-color: var(--base-level-20);
    }
  }
}
