@use '../helpers.scss' as *;

$spkPopover: true !default;

@if $spkPopover == true {
  spk-popover {
    --po-d: inherit;

    display: var(--po-d);

    .trigger {
      cursor: pointer;
      display: var(--po-d);
      position: relative;

      .trigger-wrapper {
        display: var(--po-d);

        &:empty + * {
          display: inherit;
        }

        + [spk-button] {
          display: none;
        }
      }
    }

    .overlay {
      position: fixed;
      inset: 0;
      opacity: 0;
      z-index: 1;

      + * {
        z-index: 2;
        position: relative;
      }
    }

    .popover {
      position: absolute;
      margin: 0;
      min-width: p2r(180);
      z-index: 0;
      background-color: var(--base-level-10);
      border: 1px solid var(--base-level-40);
      border-radius: var(--shape-2);
      font: var(--paragraph-30);
      color: var(--base-level-60);

      @supports (anchor-name: --anchor) {
        inset: unset;
        left: anchor(left);
        top: calc(anchor(bottom) + #{p2r(4)});
        position-try-options: flip-block, flip-inline;
        position-try-fallbacks: flip-block, flip-inline;
      }
    }
  }
}
